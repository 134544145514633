import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { jwtDecode } from 'jwt-decode';

import PasswordInput from '../../components/common/PasswordInput';
import { useNotification } from '../../contexts/NotificationProvider';
import { resetPasswordSchema, ResetPasswordForm } from '../../validation';
import { isProduction } from '../../constants';
import { DecodedToken } from '../../types';

const ResetPasswordView: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useNotification();

  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm<ResetPasswordForm>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (password && confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, confirmPassword, trigger]);

  const getTokenQuery = (): string => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    return token || '';
  };

  const getDecodedToken = (): DecodedToken | null => {
    const token = getTokenQuery();
    if (!token) return null;

    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken;
  };

  useEffect(() => {
    try {
      const decodedToken = getDecodedToken();

      if (!decodedToken || !decodedToken.user_id || !decodedToken.exp) {
        throw new Error('Token is not valid!');
      }

      setUserId(decodedToken.user_id);

      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp < currentTime) {
        throw new Error('Token is expired!');
      }
    } catch (error) {
      console.error('Invalid token:', error);

      const errorMsg =
        error instanceof Error
          ? 'Token is not valid!'
          : 'An unexpected error occurred';
      showNotification(errorMsg, 'error');
      navigate('/forgot-password');
    }
  }, [location, navigate]);

  const onSubmit = async (data: ResetPasswordForm) => {
    const { password } = data;
    const token = getTokenQuery();

    try {
      setIsLoading(true);

      const resetUserPasswordTokenURL = isProduction
        ? '/api/v1/reset_user_password'
        : 'http://localhost:8088';
      const payload = { token, new_password: password };

      await axios.post(resetUserPasswordTokenURL, payload);

      showNotification('Your password has been successfully reset.', 'success');
      navigate('/login');
    } catch (error) {
      let errorMsg;
      if (axios.isAxiosError(error)) {
        errorMsg = error.response?.data?.error || error.message;
        console.error(
          'Error during password reset token generation:',
          errorMsg
        );
      } else if (error instanceof Error) {
        console.error('An unexpected error occurred:', error.message);
        errorMsg = error.message;
      } else {
        console.error('An unknown error occurred.', error);
      }
      showNotification(errorMsg, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col gap-8 justify-center px-6 py-16 lg:px-8 sm:mx-auto sm:w-full sm:max-w-2xl">
      <div className="flex flex-col gap-4 w-full">
        <h2 className="text-4xl font-bold leading-9 tracking-tight text-gray-900">
          Set a password
        </h2>
        <p className="text-sm text-gray-500">
          Your previous password has been reset. Please set a new password for
          your account.
        </p>
      </div>

      <div className="w-full">
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            register={register}
            errors={errors}
            label="Password"
            id="password"
          />
          <PasswordInput
            register={register}
            errors={errors}
            label="Confirm Password"
            id="confirmPassword"
          />

          <div>
            <button
              type="submit"
              className={`w-full justify-center owler-indigo-button ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLoading}
              aria-disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Set password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordView;
