import React from 'react';
import { TextField } from '@mui/material';

interface PasswordInputProps {
  register: any;
  errors: any;
  label: string;
  id: string;
}

const TextInput: React.FC<PasswordInputProps> = ({
  register,
  errors,
  label,
  id,
}) => {
  return (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      {...register(id)}
      error={!!errors[id]}
      helperText={errors[id] ? errors[id].message : ''}
      fullWidth
      margin="normal"
      inputProps={{
        className: 'focus:ring-0',
      }}
      aria-invalid={errors[id] ? 'true' : 'false'}
    />
  );
};

export default TextInput;
