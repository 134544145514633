import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';

import { useNotification } from '../../contexts/NotificationProvider';
import TextInput from '../../components/common/TextInput';
import { forgotPasswordSchema, ForgotPasswordForm } from '../../validation';
import { isProduction } from '../../constants';

import ResetConfirmation from '../../components/auth/ResetConfirmation';

const ForgotPasswordView: React.FC = () => {
  const { showNotification } = useNotification();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  useEffect(() => {
    if (!isError || !error) return;

    showNotification(error, 'error');
  }, [isError, error]);

  const onSubmit = async (data: ForgotPasswordForm) => {
    const { email } = data;
    const base_url = `${window.location.protocol}//${window.location.host}`;

    try {
      setIsLoading(true);
      setIsError(false);

      const generateResetPasswordTokenURL = isProduction
        ? '/api/v1/send_reset_password_email'
        : 'http://localhost:8087';
      const payload = { email, base_url };

      const { data } = await axios.post(generateResetPasswordTokenURL, payload);

      setIsSubmitted(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMsg = error.response?.data?.error || error.message;
        setError(errorMsg);
        setIsError(true);
        console.error(
          'Error during password reset token generation:',
          errorMsg
        );
      } else if (error instanceof Error) {
        setError(error.message);
        setIsError(true);
        console.error('An unexpected error occurred:', error.message);
      } else {
        console.error('An unknown error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return <ResetConfirmation />;
  }

  return (
    <div className="flex min-h-full flex-1 flex-col gap-8 justify-center px-6 py-16 lg:px-8 sm:mx-auto sm:w-full sm:max-w-2xl">
      <div className="flex flex-col gap-4 w-full">
        <Link to="/login" className="flex gap-1 items-center">
          <ArrowBackIosIcon fontSize="small" />
          Back to login
        </Link>
        <h2 className="text-4xl font-bold leading-9 tracking-tight text-gray-900">
          Forgot your password?
        </h2>
        <p className="text-sm text-gray-500">
          Don’t worry, happens to all of us. Enter your email below to recover
          your password.
        </p>
      </div>

      <div className="w-full">
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-bold leading-6 text-gray-900"
            >
              Enter your user account's email address, and we will send you a
              password reset link.
            </label>
            <div className="mt-2">
              <TextInput
                id="email"
                label="Email"
                register={register}
                errors={errors}
              />
            </div>
          </div>

          <button
            type="submit"
            className={`w-full justify-center owler-indigo-button ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isLoading}
            aria-disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </form>
      </div>

      {/* <div className="flex items-center">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="px-4 text-gray-500 text-sm">Or login with</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div> */}

      {/* <div className="flex gap-2 w-full">
        <button
          type="button"
          className="flex-1 flex justify-center py-2 rounded-[4px] border border-[#515DEF]"
        >
          <img
            src={FacebookLogo}
            style={{ height: 24, width: 24 }}
            alt="facebook logo"
          />
        </button>
        <button
          type="button"
          className="flex-1 flex justify-center py-2 rounded-[4px] border border-[#515DEF]"
        >
          <img
            src={GoogleLogo}
            style={{ height: 24, width: 24 }}
            alt="google logo"
          />
        </button>
        <button
          type="button"
          className="flex-1 flex justify-center py-2 rounded-[4px] border border-[#515DEF]"
        >
          <img
            src={AppleLogo}
            style={{ height: 24, width: 24 }}
            alt="apple logo"
          />
        </button>
      </div> */}
    </div>
  );
};

export default ForgotPasswordView;
