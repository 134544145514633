import React, { useState } from 'react';
import { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import RubricRow from './RubricRow';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { containsInvalidCharactersForFirestore } from '../../utils';
import { useApi } from '../../contexts/ApiProvider';
import { Rubric } from '../../Types';
import preselectedRubrics from './preselectedRubrics.json';

interface RubricFormProps {
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  submit: (formData: any) => void;
  goBack: () => void;
}

const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36);
};

const createRubricWithId = (rubric: Omit<Rubric, 'id'>): Rubric => ({
  ...rubric,
  id: generateUniqueId(),
  points_possible: rubric.points_possible.toString(),
  points_earned: undefined,
  explanation: undefined,
  color: undefined,
  highlighted_text: undefined,
});

const RubricForm: React.FC<RubricFormProps> = ({
  assignmentMetadataContentModel,
  submit,
  goBack,
}) => {
  const { api, analyticsApi } = useApi();
  const userId = api.getCurrentUserId();
  const [rubricErrors, setRubricErrors] = useState<{ [key: string]: string }>(
    {}
  );

  const validateRubrics = () => {
    const newErrors: { [key: string]: string } = {};
    let isValid = true;

    assignmentMetadataContentModel.AssignmentMetadataContent.rubric.forEach(
      (rubric, index) => {
        if (!rubric.name) {
          isValid = false;
          newErrors[`name-${index}`] = 'Rubric name required';
        }
        if (containsInvalidCharactersForFirestore(rubric.name)) {
          isValid = false;
          newErrors[`name-${index}`] =
            'Rubric name cannot contain ~, *, /, [, or ]';
        }
        if (!rubric.description) {
          isValid = false;
          newErrors[`description-${index}`] = 'Rubric description required';
        }
        if (!rubric.points_possible) {
          isValid = false;
          newErrors[`points_possible-${index}`] = 'Points possible required';
        }
      }
    );

    setRubricErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validateRubrics()) {
      const userId = api.getCurrentUserId();
      analyticsApi.logRubricValidatedAndSubmitted(userId);
      submit(assignmentMetadataContentModel.AssignmentMetadataContent);
    }
  };

  const handlePreselectedRubric = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRubric = e.target.value as keyof typeof preselectedRubrics;
    if (selectedRubric in preselectedRubrics) {
      const rubricsWithIds =
        preselectedRubrics[selectedRubric].map(createRubricWithId);
      assignmentMetadataContentModel.setRubrics(rubricsWithIds);
    }
  };

  return (
    <div className="mx-auto max-w-5xl p-6 space-y-6 bg-white rounded-md">
      <h1 className="text-xl font-semibold">Create Rubric</h1>
      <p>
        Below you can add a rubric for this assignment, broken down into Rubric
        Components. A Rubric Category is a broad category for grading (e.g.,
        Grammar, Content, etc.). A Description is a more specific description of
        what you are looking for in that category (e.g., Grammar: No spelling
        errors, Content: 3-5 pages, etc.). Points Possible is the number of
        points that can be earned in that category. We recommend adding at least
        3 rubric components and keeping the descriptions below 5 sentences.
        <br></br>
        <br></br>
        You can also select a preloaded rubric from the dropdown menu.
      </p>

      <div className="mb-4">
        <label
          htmlFor="preselectedRubric"
          className="block text-sm font-medium text-gray-700"
        ></label>
        <select
          id="preselectedRubric"
          onChange={handlePreselectedRubric}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          <option value="">-- Select Rubric --</option>
          <option value="arthis">
            AP Art History Visual/Contextual Analysis
          </option>
          <option value="compgov">
            AP Comparative Government and Politics Argument Essay
          </option>
          <option value="englangsyn">
            AP English Language and Composition Synthesis Essay
          </option>
          <option value="englangrhe">
            AP English Language and Composition Rhetorical Analysis
          </option>
          <option value="englangarg">
            AP English Language and Composition Argument Essay
          </option>
          <option value="englitpoe">
            AP English Literature and Composition Poetry Analysis
          </option>
          <option value="englitpro">
            AP English Literature and Composition Prose Fiction Analysis
          </option>
          <option value="englitlit">
            AP English Literature and Composition Literary Argument
          </option>
          <option value="hmhprocess">HMH Writing as a Process</option>
          <option value="hmhevidence">HMH Using Textual Evidence</option>
          <option value="ny2ela">NY State 2 Point ELA Rubric</option>
          <option value="ny4ela">NY State 4 Point ELA Rubric</option>
          <option value="elainfosym">
            Informative Paragraph Rubric - Symbols
          </option>
          <option value="elainfotheme">
            Informative Paragraph Rubric - Theme
          </option>
        </select>
      </div>

      <div className="mt-2 overflow-hidden border border-gray-200 rounded-md">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="w-1/4 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Name
              </th>
              <th
                scope="col"
                className="w-1/2 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Description
              </th>
              <th
                scope="col"
                className="w-1/4 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Points Possible
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {assignmentMetadataContentModel.AssignmentMetadataContent.rubric.map(
              (rubric, index) => (
                <RubricRow
                  index={index}
                  rubric={rubric}
                  assignmentMetadataContentModel={
                    assignmentMetadataContentModel
                  }
                  key={rubric.id}
                  rubricErrors={rubricErrors}
                />
              )
            )}
            <tr>
              <td colSpan={3} className="px-6 py-4">
                <button
                  onClick={() => {
                    assignmentMetadataContentModel.addRubric();
                    analyticsApi.logAddRubricCategory(userId);
                  }}
                  className="owler-green-button w-full"
                >
                  Add Rubric Category
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex justify-between">
        <button onClick={goBack} className="owler-indigo-secondary-button">
          <ArrowLeftIcon className="w-5 h-5 mr-2" />
          Previous
        </button>
        <button onClick={handleSubmit} className="owler-indigo-button">
          Create Rubric
          <ArrowRightIcon className="w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );
};

export default RubricForm;
